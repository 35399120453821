import React, { useState, useEffect, ReactNode } from 'react';
import Papa from 'papaparse';
import './App.css';
import { auth, provider, db } from './firebaseConfig';
import { signInWithPopup, signOut,signInWithRedirect ,getRedirectResult } from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, collection, query, orderBy, getDocs } from 'firebase/firestore';
import { BrowserRouter as Router, Route, Link, Routes, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

interface Recipe {
  count: ReactNode;
  id: string;
  recipeName: string;
  photoURL: string;
  description: string;
  prefecture: string;
  linkURL: string;
}

interface User {
  uid: string;
  email: string | null;
  displayName: string | null;
}
interface FavoritesRankingProps {
  showRecipe: (id: string) => void;
}

const RecipeDisplay: React.FC<{ recipe: Recipe, onFavorite: () => void, isFavorited: boolean }> = ({ recipe, onFavorite, isFavorited }) => {
  return (<div className="container">
    <h2>{recipe.recipeName} | {recipe.prefecture}</h2>
    <div className="ranking-content">
      <img src={recipe.photoURL} alt={recipe.recipeName} className="recipe-photo" />
    </div>
    <p>{recipe.description}</p>
    <button onClick={onFavorite}>{isFavorited ? 'お気に入りに追加済み' : 'お気に入りに追加'}</button>
    <p><a href={recipe.linkURL} target="_blank" rel="noopener noreferrer"> 農林水産省のWebサイトで詳細を確認する</a></p>
  </div>

  );
};
const FavoritesRanking: React.FC<FavoritesRankingProps> = ({ showRecipe }) => {
  const [ranking, setRanking] = useState<Recipe[]>([]);
  const [recipeMap, setRecipeMap] = useState<Map<string, Recipe>>(new Map());
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCSVData = async () => {
      Papa.parse('/recipes.csv', {
        header: true,
        download: true,
        complete: (result) => {
          const recipeData = result.data as Recipe[];
          const map = new Map(recipeData.map(item => [item.id, item]));
          setRecipeMap(map);
        },
        skipEmptyLines: true,
      });
    };

    const fetchRanking = async () => {
      const q = query(collection(db, "favoritesRanking"), orderBy("count", "desc"));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const rankingData = querySnapshot.docs.map(doc => doc.data() as Recipe);
        setRanking(rankingData);
      } else {
        setRanking([]);
      }
    };

    fetchCSVData();
    fetchRanking();
  }, []);

  const handleRecipeClick = (id: string) => {
    showRecipe(id);
    navigate('/');
  };

  return (
    <div className="ranking-container">
      <h2>お気に入り数ランキング</h2>
      <ul>
        {ranking
          .filter(item => typeof item.count === 'number' && item.count > 0) // 数値型かつ0より大きいかを確認
          .map((item, index) => {
            const recipe = recipeMap.get(item.id);

          if (index < 3 && recipe) {
            return (
              <li key={index} className="ranking-item">
                <div className="ranking-number">{index + 1}位　
                  <span className="recipe-name" onClick={() => handleRecipeClick(recipe.id)}>{recipe.recipeName}</span>
                  <span className="recipe-prefecture"> | {recipe.prefecture}</span>
                  <span className="recipe-count"> ({item.count}お気に入り)</span>
                </div>
                <div className="ranking-content">
                  <img src={recipe.photoURL} alt={recipe.recipeName} className="recipe-photo" />
                  <div className="recipe-details">
                  </div>
                </div>
              </li>
            );
          } else if (index < 10 && recipe) {
            return (
              <li key={index} className="ranking-item">
                <span className="ranking-number">{index + 1}位　</span>
                <span className="recipe-name" onClick={() => handleRecipeClick(recipe.id)}> {recipe.recipeName}</span>
                <span className="recipe-prefecture"> | {recipe.prefecture}</span>
                <span className="recipe-count"> ({item.count}お気に入り)</span>
              </li>
            );
          }
          return null;
        })}
      </ul>
    </div>
  );
};


const App: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[]>([]);
  const [randomRecipe, setRandomRecipe] = useState<Recipe | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [favorites, setFavorites] = useState<Recipe[]>([]);

  useEffect(() => {
    const handleRedirectResult = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result && result.user) {
          const currentUser: User = {
            uid: result.user.uid,
            email: result.user.email,
            displayName: result.user.displayName
          };
          setUser(currentUser);
        }
      } catch (error) {
        console.error("Error getting redirect result:", error);
      }
    };

    handleRedirectResult();

    Papa.parse('/recipes.csv', {
      header: true,
      download: true,
      complete: (result) => {
        setRecipes(result.data as Recipe[]);
        if (result.data.length > 0) {
          setRandomRecipe(result.data[Math.floor(Math.random() * result.data.length)] as Recipe);
        }
      },
      skipEmptyLines: true,
    });

    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const currentUser: User = {
          uid: user.uid,
          email: user.email,
          displayName: user.displayName
        };
        setUser(currentUser);
        await loadFavorites(currentUser.uid);
      } else {
        setUser(null);
        setFavorites([]);
      }
    });
  }, []);

  const handleNewRecipe = () => {
    if (recipes.length > 0) {
      const newRecipe = recipes[Math.floor(Math.random() * recipes.length)];
      setRandomRecipe(newRecipe);
    }
  };

  const handleSignIn = () => {
    signInWithPopup(auth, provider).catch((error) => {
      console.error("Sign in error:", error);
    });
  };

  const handleSignOut = () => {
    signOut(auth).catch((error) => {
      console.error("Sign out error:", error);
    });
  };

  const handleFavorite = async () => {
    if (user && randomRecipe) {
      try {
        const favoriteRef = doc(db, "favorites", user.uid);
        const favoriteDoc = await getDoc(favoriteRef);

        if (favoriteDoc.exists()) {
          const currentFavorites = favoriteDoc.data().recipes as Recipe[];
          const alreadyFavorited = currentFavorites.some(fav => fav.id === randomRecipe.id);

          if (alreadyFavorited) {
            // お気に入りから削除
            const updatedFavorites = currentFavorites.filter(fav => fav.id !== randomRecipe.id);
            await updateDoc(favoriteRef, {
              recipes: updatedFavorites
            });
            setFavorites(updatedFavorites);
            await decrementFavoriteCount(randomRecipe.id);
          } else {
            // お気に入りに追加
            await updateDoc(favoriteRef, {
              recipes: [...currentFavorites, {
                id: randomRecipe.id,
                recipeName: randomRecipe.recipeName,
                prefecture: randomRecipe.prefecture
              }]
            });
            setFavorites([...currentFavorites, {
              id: randomRecipe.id,
              recipeName: randomRecipe.recipeName,
              prefecture: randomRecipe.prefecture,
              photoURL: '',
              description: '',
              linkURL: '',
              count: undefined
            }]);
            await incrementFavoriteCount(randomRecipe.id);
          }
        } else {
          // 新しいお気に入りリストを作成
          await setDoc(favoriteRef, {
            recipes: [{
              id: randomRecipe.id,
              recipeName: randomRecipe.recipeName,
              prefecture: randomRecipe.prefecture
            }]
          });
          setFavorites([{
            id: randomRecipe.id,
            recipeName: randomRecipe.recipeName,
            prefecture: randomRecipe.prefecture,
            photoURL: '',
            description: '',
            linkURL: '',
            count: undefined
          }]);
          await incrementFavoriteCount(randomRecipe.id);
        }
      } catch (error) {
        console.error("Error saving favorite:", error);
      }
    }
  };

  const decrementFavoriteCount = async (recipeId: string) => {
    const favoriteCountRef = doc(db, "favoritesRanking", recipeId);
    const favoriteCountDoc = await getDoc(favoriteCountRef);

    if (favoriteCountDoc.exists()) {
      const newCount = favoriteCountDoc.data().count - 1;
      if (newCount > 0) {
        await updateDoc(favoriteCountRef, {
          count: newCount
        });
      } else {
        // お気に入りが0の場合、ドキュメントを削除
        await setDoc(favoriteCountRef, {
          id: recipeId,
          count: 0
        });
      }
    }
  };

  const incrementFavoriteCount = async (recipeId: string) => {
    const favoriteCountRef = doc(db, "favoritesRanking", recipeId);
    const favoriteCountDoc = await getDoc(favoriteCountRef);

    if (favoriteCountDoc.exists()) {
      await updateDoc(favoriteCountRef, {
        count: favoriteCountDoc.data().count + 1
      });
    } else {
      await setDoc(favoriteCountRef, {
        id: recipeId,
        count: 1
      });
    }
  };

  const loadFavorites = async (uid: string) => {
    try {
      const favoriteRef = doc(db, "favorites", uid);
      const favoriteDoc = await getDoc(favoriteRef);

      if (favoriteDoc.exists()) {
        setFavorites(favoriteDoc.data().recipes as Recipe[]);
      } else {
        setFavorites([]);
      }
    } catch (error) {
      console.error("Error loading favorites:", error);
      setFavorites([]);
    }
  };

  const showRecipe = (id: string) => {
    console.log(id)
    const selectedRecipe = recipes.find(recipe => recipe.id === id);
    if (selectedRecipe) {
      setRandomRecipe(selectedRecipe);
    }
  };

  return (
    
    <Router>
      <Helmet>
        <title>日本の郷土料理 - ランダムレシピとお気に入りランキング</title>
        <meta name="description" content="日本の郷土料理をランダムで表示し、お気に入りすることができるウェブサイトです。お気に入り数に基づく人気ランキングも提供しています。" />
        <meta name="keywords" content="郷土料理, レシピ, 日本, お気に入り, 人気ランキング" />
      </Helmet>
      <div>
        <div className="header">
          <h1 className="title">日本の郷土料理</h1>
        </div>
        <p className="description" >日本の郷土料理をランダムで表示するウェブサイトです。<br></br>お気に入りすることができ、お気に入り数で人気ランキングを作っています。</p>

        <nav className='separator'>
          <Link to="/">ランダム表示</Link> | <Link to="/ranking">お気に入りランキング</Link>
        </nav>
        <Routes>
          <Route path="/" element={
            <div>
              <nav>
                <button onClick={handleNewRecipe}>他のレシピを表示</button><br></br>
              </nav>
              {randomRecipe && (
                <RecipeDisplay
                  recipe={randomRecipe}
                  onFavorite={handleFavorite}
                  isFavorited={favorites.some(fav => fav.id === randomRecipe.id)}
                />
              )}
              <div className="favorites-list">
                <h1 className="title">あなたのお気に入り</h1><br></br>  <br></br>   {favorites.map((recipe, index) => (
                  <button key={index} className="favorite-item" onClick={() => showRecipe(recipe.id)}>
                    {recipe.recipeName} ({recipe.prefecture})
                  </button>
                ))}
              </div>

              <div className="auth-buttons">
                {user ? (
                  <div>
                    <p>{user.displayName}さんとしてログインしています</p>
                    <button onClick={handleSignOut}>Sign Out</button>
                  </div>
                ) : (

                    <div>
                  <p>お気に入りするためにはログインが必要です。</p>
                      <button onClick={handleSignIn}>Sign in with Google</button></div>
                )}
              </div>
            </div>
          } />
          <Route path="/ranking" element={<FavoritesRanking showRecipe={showRecipe} />} />
        </Routes>

        <div className="note">
          出典: <a href="https://www.maff.go.jp/j/keikaku/syokubunka/k_ryouri/" target="_blank" rel="noopener noreferrer">農林水産省 うちの郷土料理</a>
        </div>
      </div>
    </Router>
  );
};

export default App;
