
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCfMsgKVmBh4QqO1WiPtkMJqKp1KerpziE",
    authDomain: "kyoudoryouri-4e270.firebaseapp.com",
    projectId: "kyoudoryouri-4e270",
    storageBucket: "kyoudoryouri-4e270.appspot.com",
    messagingSenderId: "353523192775",
    appId: "1:353523192775:web:00fb71f9b552ddaaa97fe2",
    measurementId: "G-NXY5RK6T4Z"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);

export { auth, provider, db };
